  * {
    padding: 0px;
    box-sizing: border-box;
    margin: 0px;
  }

  body {
    background-color: #f8f8f8;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    color: #000;
    line-height: 18px;
  }

  @font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  .form-group .custom_form_control input {
    background: #f2f2f2;
    border-radius: 8px;
    padding: 6.89px 20px 6.89px 15px;
    font-size: 12px;
    line-height: 18px;
    color: #999999;
    font-family: "Poppins-Regular";
  }

  .form-group .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #1f6feb;
  }

  .form-group .custom_form_control input::placeholder {
    font-size: 12px;
    line-height: 18px;
    color: #999999;
    font-family: "Poppins-Regular";
  }

  .form-group .label_control {
    font-size: 12px;
    line-height: 18px;
    color: #676767;
    min-height: 18px;
    margin: 0px 0px 2px;
    font-family: "Poppins-SemiBold";
    text-transform: capitalize;
  }

  .dropdown-box .MuiInputBase-root {
    border-radius: 8px;
    background: #f2f2f2;
  }

  .Mui-error input {
    border: 1px solid red;
  }

  .dropdown-box .Mui-error input {
    border-color: transparent !important;
  }

  .dropdown-box .Mui-error {
    border: 1px solid red !important;
  }

  .form-group .custom_form_control input::placeholder {
    color: #999999;
    font-size: 12px;
    line-height: 18px;
    font-family: "Poppins-Regular";
    opacity: 1;
  }

  .login_pd_container {
    padding: 30px;
  }

  .bg_logins {
    background-image: linear-gradient(45deg,
        hsl(0deg 0% 0% / 40%),
        hsl(0deg 0% 0% / 40%)),
      url("../images/jpg/login-bg.jpg");
    width: 100%;
    height: calc(100vh - 60px);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 27px;
    display: flex;
    align-items: center;
    padding: 30px 10px;
  }

  .cus_row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .left_bg_login {
    flex: 1;
  }

  .right_content_login {
    max-width: 530px;
    width: 100%;
    flex: 0 0 530px;
  }

  .content_details_login {
    padding: 0px 60px;
  }

  .title_login_details .back_welcome_title {
    color: #000000;
    font-family: "Poppins-SemiBold";
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 34px;
  }

  .title_login_details .signin_account_text {
    color: #999999;
    font-size: 14px;
    line-height: 20px;
    font-family: "Poppins-Medium";
  }

  .field_name_list .from_login_details:last-child {
    margin-bottom: 0px;
  }

  .from_login_details {
    margin-bottom: 15px;
  }

  .forgot_link_end {
    margin: 10px 0px 0px 0px;
    text-align: end;
  }

  .login_button {
    margin: 40px 0px 0px 0px;
  }

  .forgot_link_end .forgot_link_text {
    color: #999999;
    font-size: 13px;
    line-height: 20px;
    text-decoration: none;
    font-family: "Poppins-Medium";
  }

  .logo_login {
    height: 68.13px;
    object-fit: contain;
    max-width: 167.33px;
  }

  .login_details_main {
    max-width: 405px;
    margin: auto;
    text-align: center;
  }

  .login_title_content .main_title_login {
    color: #fff;
    font-size: 28px;
    font-family: "Poppins-Medium";
    line-height: 42px;
    margin: 0px 0px 31px 0px;
  }

  .login_title_content {
    margin-top: 50px;
  }

  .login_title_content .main_content_login {
    color: #ffffff;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 0px;
  }

  .primary-btn-main .primary_button,
  .primary-btn-main .primary_button:hover {
    background-color: #000;
    width: 100%;
    color: #fff;
    text-transform: capitalize;
    border-radius: 8px;
    min-height: 40px;
    font-family: "Poppins-Regular";
    font-size: 16px;
    line-height: 25px;
    min-width: 100%;
  }

  .secondary-btn-main .secondary_button:focus {
    border: 2px solid #1f6feb;
  }

  .secondary-btn-main .secondary_button,
  .secondary-btn-main .secondary_button:hover {
    background-color: #e3e3e3;
    color: #000;
    text-transform: capitalize;
    border-radius: 5px;
    font-family: "Poppins-Regular";
    font-size: 12px;
    line-height: 18px;
    padding: 5px 20px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .login_form_field {
    margin-top: 40px;
  }

  .pass_group_main {
    position: relative;
  }

  .pass_group_main .custom_form_control {
    width: 100%;
    padding-right: 0px;
  }

  .pass_group_main .custom_form_control input {
    width: 100%;
    padding-right: 53px;
  }

  .pass_group_main .pass_position {
    margin-left: 0px;
  }

  .pass_group_main .pass_position .icon_end_icon {
    position: absolute;
    right: 15px;
  }

  .login_form_field .return_to_login_text {
    text-align: center;
    margin-top: 20px;
  }

  .main_header_deatils {
    background: #ffffff;
    border-bottom: 1px solid #ececee;
    padding: 12px 15px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9;
  }

  .header_listing .ul_list_details .li_list_accounts:first-child {
    border-left: 1px solid #ececee;
  }

  .header_logo_col1 {
    width: 100%;
    flex: 0 0 8%;
    max-width: 8%;
  }

  .black_logo {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .header_row {
    display: flex;
    align-items: center;
  }

  .black_dash_logo {
    display: flex;
    align-items: center;
  }

  .card_list_ul .card_list_li .card_img_main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile_header_main .flex-drop-main .title-admin-drop {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header_content_col2 {
    flex: 1;
  }

  .header_listing .ul_list_details {
    padding: 0px;
    display: flex;
    width: 100%;
  }

  .header_listing .ul_list_details .li_list_accounts {
    padding: 0;
    width: calc(100% / 12);
    align-items: center;
    justify-content: center;
    min-width: 65px;
    border-right: 1px solid #ececee;
  }

  .header_listing .ul_list_details .li_list_accounts .list_details_title .list_title_header {
    font-size: 9px;
    line-height: 12px;
    color: #999999;
    font-family: "Poppins-Regular";
  }

  .header_listing .ul_list_details .li_list_accounts .header_redirect_main {
    text-decoration: none;
  }

  .header_listing .ul_list_details .li_list_accounts .account_list_main {
    text-align: center;
  }

  .drop-header {
    height: 15px;
    width: 15px;
    margin-right: 6px;
  }

  .drop-header-menu .MuiPaper-rounded {
    box-shadow: none;
    margin-top: 9px;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid #ededed;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 10px 10px 20px #0000001a;
    -webkit-border-radius: 0px 0px 10px 10px;
    -moz-border-radius: 0px 0px 10px 10px;
    -ms-border-radius: 0px 0px 10px 10px;
    -o-border-radius: 0px 0px 10px 10px;
  }

  .flex-drop-main .title-admin-drop .user_hedaer {
    width: 33px;
    height: 33px;
    object-fit: cover;
    border: 1px solid #ededed;
    border-radius: 5px;
  }

  .drop-header {
    height: 15px;
    width: 15px;
    margin-right: 6px;
  }

  .drop-header-menu .drop-header-menuitem {
    padding: 0;
    border-bottom: 1px solid #ededed;
  }

  .drop-header-menu .drop-header-menuitem:last-child {
    border-bottom: 0;
  }

  .drop-header-menu .drop-header-menuitem .header_link {
    color: #999999;
    font-size: 12px;
    line-height: 20px;
    padding: 5px 10px;
    font-family: "Poppins-Regular";
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .drop-header-menu ul {
    padding: 0px;
    border: 1px solid #ededed;
    border-top: 0;
  }

  .flex-drop-main .admin-header-drop {
    font-size: 12px;
    font-family: "Poppins-Regular";
    line-height: 18px;
    color: #ffffff;
    text-align: start;
  }

  .pd_header {
    padding: 0px 24px 0px 0px;
  }

  .profile_header_main .drop-header-btn,
  .profile_header_main .drop-header-btn:hover {
    padding: 0px;
    justify-content: center;
    background-color: transparent;
    min-width: 30px;
  }

  .svg_img_main .side_logo_white {
    display: none;
  }

  .sidebar_main .ul_sidebar_list {
    padding: 0px;
  }

  .svg_img_main .side_logo,
  .svg_img_main .side_logo_white {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }

  .account_list_img {
    height: 13px;
    width: 13px;
    object-fit: contain;
  }

  .flex_main_dash {
    display: flex;
    padding-top: 54px;
    min-height: 100vh;
  }

  .sidebar_left {
    background-color: #e5e5e5;
    position: fixed;
    left: 0;
    max-width: 250px;
    min-width: 250px;
    width: 100%;
    top: 58px;
    height: calc(100vh - 80px);
    overflow: auto;
    margin-right: -10px;
    transition: all 0.5s;
    padding-right: 10px;
    padding-left: 15px;
    padding-top: 15px;
    z-index: 99;
  }

  .pd_sidebar {
    padding: 0px 0px 0px 15px;
  }

  .ul_sidebar_list .li_sidebar_list .sidebar_btn_list,
  .flex-drop-main,
  .main_content .title_top_fix .flex_dash_title {
    display: flex;
    align-items: center;
  }

  .svg_img_main {
    display: flex;
    margin-right: 10px;
  }

  .ul_sidebar_list .li_sidebar_list .redirect_sidebar_page {
    text-decoration: none;
    width: 100%;
  }

  .ul_sidebar_list .li_sidebar_list:last-child {
    margin-bottom: 0px;
  }

  .ul_sidebar_list .li_sidebar_list {
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 0px;
    min-height: 35px;
    margin-bottom: 10px;
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .sidebar_text_details {
    width: 100%;
  }

  .ul_sidebar_list .li_sidebar_list .rightdrop {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .inner_sidebar_drop .inner_sidebar_drop_list {
    padding: 0px 0px 15px 0px;
  }

  .inner_sidebar_drop .inner_sidebar_drop_list:last-child {
    padding-bottom: 0px;
  }

  .pd_content_main {
    padding: 0px 15px 0px 0px;
  }

  .main_content .title_top_fix h4 {
    font-size: 16px;
    line-height: 23px;
    min-height: 32px;
    font-family: "Poppins-SemiBold";
    color: #030303;
    position: relative;
  }

  .main_content .title_top_fix h4::after {
    position: absolute;
    content: "";
    bottom: 6px;
    left: 0px;
    background-color: #030303;
    height: 3px;
    width: 30px;
    border-radius: 10px;
  }

  .title_top_fix {
    padding: 15px;
    position: fixed;
    left: 260px;
    right: 0;
    background: #f8f8f8;
    z-index: 9;
    top: 58px;
  }

  .web_main_content {
    padding-top: 56px;
  }

  .over_text_scroll {
    margin-top: 10px;
  }

  .card_list_ul .card_list_dash .card_list_dash_body {
    padding: 15px;
  }

  .card_list_ul .card_list_li {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 15px #0000000d;
    border: 1px solid #ececee;
    border-radius: 5px;
    padding: 0px;
    transition: 0.5s all;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .list_card_details .card_list_ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    gap: 15px;
    padding: 0px;
  }

  .web_main_content .list_card_details {
    margin-bottom: 16px;
  }

  .card_list_ul .card_list_dash {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
  }

  .number_title_card .number_list {
    font-size: 21px;
    line-height: 35px;
    font-family: "Poppins-Medium";
  }

  .number_title_card .number_menu_name {
    font-size: 12px;
    line-height: 21px;
    color: #858585;
    font-family: "Poppins-Regular";
    padding-bottom: 0px;
  }

  .card_list_ul .card_list_li .card_number_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .progress_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .flex_modal_space {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .flex_space_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .flex_justify {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .progress_flex .left_progress,
  .progress_flex .right_progress {
    font-size: 10px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 2px;
    color: #858585;
  }

  .progress-bar-main {
    margin-top: 6px;
  }

  .card_list_ul .card_list_li:hover {
    background-color: #000000;
  }

  .card_list_ul .card_list_li:hover .number_title_card .number_list {
    color: #fff;
  }

  .card_list_ul .card_list_li:hover .card_img_main {
    background: transparent linear-gradient(90deg,
        #f01e5c 0%,
        #f4314a 25%,
        #f94b25 50%,
        #fa5813 74%,
        #fd6602 100%) 0% 0% no-repeat padding-box;
    border: 0px;
  }

  .card_list_ul .card_list_li:hover .card_img_main .card_icon_black_none {
    display: block;
  }

  .card_list_ul .card_list_li:hover .card_img_main .card_icon_black {
    display: none;
  }

  .card_list_ul .card_list_li:hover .progress-bar-main .progress-bar .MuiLinearProgress-bar {
    background-color: #3e3e3e;
  }

  .card_list_ul .card_list_li:hover .progress-bar-main .MuiLinearProgress-colorPrimary {
    background-color: #1a1a1a;
  }

  .progress-bar-main .MuiLinearProgress-colorPrimary {
    background-color: #ececee;
  }

  .card_list_ul .card_list_li .card_img_main {
    background: #ececee 0% 0% no-repeat padding-box;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .card_icon_black,
  .card_icon_black_none {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }

  .card_icon_black_none {
    display: none;
  }

  .progress-bar-main {
    max-width: 470px;
    width: 100%;
    position: relative;
  }

  .progress-bar-main .progress-bar .MuiLinearProgress-bar {
    border-radius: 8px;
    background-color: #cfcfcf;
  }

  .progress-bar-content {
    margin-top: 30px;
  }

  .progress-bar-content .admin-page-title {
    font-size: 20px;
    line-height: 30px;
    color: #111827;
    font-family: "Poppins-Medium";
  }

  .progress-bar-text {
    position: absolute;
    right: -35px;
    top: -5px;
    font-size: 14px;
    line-height: 21px;
    color: #6b7280;
    font-family: "Poppins-Medium";
  }

  .grid-main .grid-item {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0px;
    padding: 0px;
  }

  .graph-sec .card_graph_details {
    border: 1px solid #ececee;
    border-radius: 5px;
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .graph-sec .card_graph_body {
    padding: 20px;
  }

  .graph-sec .graph_main .graph_grid_main_column {
    padding: 0px 5.5px;
  }

  .graph-sec .graph_main .graph_grid_main {
    margin-left: -5.5px;
    margin-right: -5.5px;
    width: 100%;
  }

  .report-main-box {
    background: #ffffff;
    box-shadow: 0px 10px 15px #0000000d;
    border: 1px solid #ececee;
    border-radius: 15px;
    width: 100%;
    padding: 15px;
  }

  .common-card {
    background: #ffffff;
    box-shadow: 0px 10px 15px #0000000d;
    border: 1px solid #ececee;
    border-radius: 15px;
    padding: 15px;
  }

  .page-table-main .table-container {
    box-shadow: none;
    background-color: transparent;
    border-radius: 0px;
    max-width: 100vw;
    overflow: auto;
  }

  .border-container .table-th,
  .border-container .table-td {
    border: 1px solid #e3e3e3;
  }

  .page-table-main .table-th {
    font-size: 12px;
    line-height: 18px;
    color: #35383d;
    font-family: "Poppins-SemiBold";
    padding: 6px 15px 6px 15px;
    border-bottom: 1px solid #cfcfcf;
    background-color: #71717124;
  }

  .userdata-btn-flex .table-btns {
    min-width: 20px;
    height: 20px;
    width: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .userdata-btn-flex .table-btns:last-child {
    margin-right: 0px;
  }

  .userdata-btn-flex .table-disabled-btn {
    cursor: default;
  }

  .userdata-btn-flex .table-edit-btn,
  .userdata-btn-flex .table-edit-btn:hover {
    background-color: #e6b903;
  }

  .userdata-btn-flex .table-edit-btn.table-disabled-btn,
  .userdata-btn-flex .table-edit-btn.table-disabled-btn:hover {
    background-color: #cfc8c8;
  }

  .userdata-btn-flex .table-eye-btn,
  .userdata-btn-flex .table-eye-btn:hover {
    background-color: #61cfff;
  }

  .userdata-btn-flex .table-trash-btn,
  .userdata-btn-flex .table-trash-btn:hover {
    background-color: #f91030;
  }

  .preview-underline {
    text-decoration: underline;
  }

  .table-icons {
    height: 10px;
    width: 10px;
    object-fit: contain;
  }

  .btn_linear .linear-btn-main:focus {
    border: 2px solid #1f6feb;
  }

  .btn_linear .linear-btn-main,
  .btn_linear .linear-btn-main:hover {
    background: transparent linear-gradient(90deg,
        #f01e5c 0%,
        #f4314a 25%,
        #f94b25 50%,
        #fa5813 74%,
        #fd6602 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #fff;
    text-transform: capitalize;
    font-family: "Poppins-Regular";
    font-size: 12px;
    line-height: 18px;
    padding: 5px 20px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    box-shadow: none;
    font-family: "Poppins-Medium";
  }

  .btn_right_align {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 12px 25px 6px 25px;
  }

  .mr-16px {
    margin-right: 16px;
  }

  .tab_card {
    background: #ffffff;
    box-shadow: 0px 10px 15px #0000000d;
    border: 1px solid #ececee;
    border-radius: 15px;
    position: relative;
  }

  .tab_card .tab_card_body {
    padding: 20px;
  }

  .tab_main_details .tabs_list .tab_list_lable {
    font-size: 14px;
    text-transform: capitalize;
    color: #000000;
    font-family: "Poppins-Regular";
    line-height: 25px;
    max-height: 34px;
    min-width: 181px;
    min-height: 34px;
  }

  .tab_main_details .tabs_list .tab_list_lable.Mui-selected {
    background: transparent linear-gradient(90deg,
        #f01e5c 0%,
        #f4314a 25%,
        #f94b25 50%,
        #fa5813 74%,
        #fd6602 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
    border-bottom: 0px;
    padding: 8.5px;
  }

  .tab_main_details .tabs_list .MuiTabs-indicator {
    display: none;
  }

  .tab_border_content .tabs_list {
    min-height: 34px;
  }

  .edit_profile_field .from_login_details .custom_form_control input {
    padding: 3.5px 10px 3.5px 10px;
    min-height: 27px;
    font-size: 14px !important;
    color: #000000;
  }

  .edit_profile_field .from_login_details .label_control {
    font-size: 12px;
    line-height: 21px;
    color: #000000;
    font-family: "Poppins-Bold";
    margin-bottom: 6px;
    margin-top: 0px;
    font-family: "Poppins-SemiBold";
    text-transform: capitalize;
  }

  .edit_grid_main {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }

  .edit_grid_main_column {
    padding: 0px 7.5px;
  }

  .tabpanel_list {
    padding-top: 15px;
  }

  .switch_main {
    position: absolute;
    right: 4px;
    top: 39px;
    bottom: 0;
  }

  .switch_main .switch_toggle {
    padding: 0px;
    min-width: 60px;
    min-height: 32px;
    font-family: "Poppins-Regular";
    text-transform: capitalize;
    line-height: 21px;
    font-size: 14px;
    border-radius: 8px;
  }

  .switch_main .switch_toggle.Mui-selected {
    background: #000000;
    color: #fff;
  }

  .flex_modal_space .cancel-img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    cursor: pointer;
  }

  .add_modal_form .edit_grid_main .edit_grid_main_column {
    padding: 0px 7.5px;
  }

  .add_modal_form .edit_grid_main {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }

  .add_modal_form .btn_right_align {
    margin-top: 10px;
  }

  .ordergraph-img {
    width: 100%;
    height: 100%;
  }

  .search_list_main {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .profile-img {
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    width: 60px;
  }

  .flex_start_profile {
    height: 60px;
    border-radius: 50%;
    border: 1px solid lightgray;
    width: 60px;
    position: relative;
  }

  .flex_start_profile .input_label_btn,
  .flex_start_profile .input_label_btn:hover {
    background-color: #c85803;
    border-radius: 50%;
    bottom: 0px;
    box-shadow: 0 3px 6px #00000029;
    height: 17px;
    min-width: 17px;
    padding: 0;
    position: absolute;
    right: 0;
    width: 17px;
  }

  .search_list_main .search_input {
    position: relative;
  }

  .form-group .search_control input {
    padding: 0px 43px 1px 10px;
    height: 25px;
    border: 1px solid #e3e3e3;
    min-width: 100px;
  }

  .form-group .search_control input::placeholder {
    font-size: 12px;
    line-height: 18px;
    color: #999999;
    font-family: "Poppins-Regular";
  }

  .search_btn {
    position: absolute;
    right: 2px;
    top: 1.2px;
  }

  .search_btn .btn_search,
  .search_btn .btn_search:hover {
    background: #000;
    padding: 5px;
    min-width: 25px;
    border-radius: 5px;
    min-height: 25px;
  }

  .search_btn img {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }

  .dropdown-box .custom_form_control {
    width: 100%;
    position: relative;
  }

  .dropdown-box .form-control .dropdown-select {
    background: #f2f2f2;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
  }

  .dropdown-select .MuiSelect-select {
    padding: 6.5px 15px;
    font-size: 12px;
    line-height: 18px;
    color: #999999;
    font-family: "Poppins-Regular";
    text-align: left;
  }

  .grey-down {
    position: absolute;
    top: 17.92px;
    right: 21.92px;
    height: 10px;
    width: 10px;
    object-fit: contain;
  }

  .form-group .custom_form_control_textarea {
    width: 100%;
    background: #f2f2f2;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    font-family: "Poppins-Regular";
    padding: 7px 20px 7px 20px;
    font-size: 14px;
    line-height: 21px;
    color: #999999;
    font-family: "Poppins-Regular";
  }

  .form-group .custom_form_control_textarea:active,
  .form-group .custom_form_control_textarea:focus {
    border: 1px solid #1f6feb;
  }

  .MuiMenu-list .menuitem {
    font-size: 12px;
    line-height: 15px;
    color: #676767;
    font-family: "Poppins-Medium";
  }

  .list_main_details .list_top_view_company {
    position: relative;
    margin-bottom: 10px;
  }

  .list_main_details .list_top_view_company .span-border-bottom::after {
    content: "";
    height: 1px;
    background-color: #e3e3e3;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .list_main_details .list_top_view_company .company_title_view {
    background-color: #ececee;
    padding: 5px 10px;
    border-radius: 6px 6px 0px 0px;
    font-size: 13px;
    line-height: 18px;
    font-family: "Poppins-Medium";
    width: max-content;
    color: #c85803;
  }

  .view_rows .details_view_show {
    text-align: start;
    display: flex;
    gap: 10px;
  }

  .view_rows .details_view_show .title_view_com {
    color: #000000;
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 3px;
    font-family: "Poppins-Medium";
  }

  .view_rows .details_view_show .desc_view_com {
    color: #858585;
    font-size: 11px;
    line-height: 15px;
    font-family: "Poppins-Regular";
  }

  .mt-15px {
    margin-top: 15px;
  }

  .list_view_edit .btn_right_align {
    margin: 30px 0px 0px 0px;
  }

  .userdata-btn-flex {
    display: flex;
    cursor: pointer;
    gap: 7px;
    align-items: center;
  }

  .office-address-space {
    margin-bottom: 6px;
  }

  .validation .MuiFormHelperText-sizeMedium {
    margin-left: 2px !important;
  }

  .requried-star {
    color: #d32f2f;
    font-size: 14px;
  }

  .max-width-edit {
    max-width: 352px;
  }

  .max-width-edit .btn_right_align {
    margin-right: 0px;
  }

  .error-status {
    color: #d32f2f !important;
  }

  .success-status {
    color: #008000 !important;
  }

  .pagination-table-design {
    padding: 10px 0px 0px 0px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .stack-pagination .stack-pagination-list .MuiPagination-ul li .MuiPaginationItem-outlined.Mui-selected {
    background-color: #000;
    border-color: #000000;
    color: #fff;
    font-size: 13px;
    line-height: 16px;
  }

  .stack-pagination .stack-pagination-list .MuiPagination-ul li .MuiPaginationItem-outlined {
    background-color: #ffffff;
    border-color: #000000;
    color: #000;
    min-width: 20px;
    height: 20px;
    border-radius: 8px;
    font-size: 13px;
    line-height: 16px;
  }

  .stack-pagination .stack-pagination-list .MuiPagination-ul li .MuiPaginationItem-outlined.MuiPaginationItem-ellipsis {
    color: #000000;
  }

  .stack-pagination .stack-pagination-list .MuiPagination-ul li .MuiPaginationItem-outlined.Mui-disabled {
    color: black;
  }

  .table-btns .active-dactive-btn {
    height: 11px;
    width: 11px;
    color: #fff;
  }

  .color-green {
    background-color: #008000 !important;
  }

  .modal-main .modal-inner {
    max-width: 1200px;
    border: 0px;
    margin: auto;
    outline: none;
    width: 100%;
  }

  .swal2-popup {
    width: 24em !important;
  }

  .swal2-html-container {
    font-size: 15px !important;
  }

  .swal2-styled.swal2-cancel {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #6e7881;
    color: #fff;
    font-size: 0.8em;
  }

  .swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #7066e0;
    color: #fff;
    font-size: 0.8em;
  }

  .modal-edit-content-fixed {
    z-index: 9;
    padding: 20px 25px 12px 25px;
    border-bottom: 1px solid #e3e3e3;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .modal-edit-content-fixed h4.add_modal_title {
    font-size: 20px;
    line-height: 33px;
    color: #000000;
    font-family: "Poppins-Bold";
  }

  .edit-height-fixed {
    max-height: calc(100vh - 160px);
    overflow: auto;
    margin-bottom: 10px;
  }

  .edit-height-fixed::-webkit-scrollbar-track {
    background: #ececee;
    border-radius: 10px;
  }

  .edit-height-fixed::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* .edit-height-fixed::-webkit-scrollbar-thumb {
      background: #C1C1C1;
      border-radius: 10px;
  } */

  .upload-add-finish-img {
    height: 100px;
    width: 100%;
  }

  .add-set-table-main .add-set-table-container {
    box-shadow: none;
    border-radius: 5px;
    background: #f8f8f8;
    border: 1px solid #cfcfcf;
    overflow-y: auto;
    overflow-x: hidden;
    height: 300px;
  }

  .add-set-icons {
    height: 9px;
    width: 9px;
    object-fit: contain;
  }

  .add-set-lable-flex {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
  }

  .add-set-lable-flex .add-set-lable-text {
    font-size: 13px;
    line-height: 21px;
    color: #000000;
    font-family: "Poppins-Medium";
    margin-right: 20px;
  }

  .add-set-count-box {
    border-radius: 5px;
    background-color: #e3e3e3;
    height: 30px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-set-count-box .add-set-count-text {
    font-size: 13px;
    line-height: 21px;
    color: #858585;
    font-family: "Poppins-Regular";
  }

  .add-set-icons-main .MuiButtonBase-root {
    padding: 0px;
  }

  .ml-upload-img-group .input_label_btn,
  .ml-upload-img-group .input_label_btn:hover {
    margin-left: 0px;
  }

  .grouping_column .grouping-table-list-modal .table-container {
    padding: 0px;
    overflow: auto;
    margin-bottom: 20px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .btn-qty-box,
  .right-btn-modal {
    display: flex;
    align-items: center;
  }

  .btn-space-between {
    display: flex;
    justify-content: space-between;
  }

  .min-width-control input {
    min-width: 250px;
  }

  .note-tax-master span {
    font-size: 10px;
    line-height: 15px;
    color: #9d9d9d;
    text-align: start;
    width: 100%;
    display: block;
  }

  .width-check-controller {
    width: 80%;
  }

  .checkbox-details-tax {
    display: flex;
    align-items: center;
  }

  .flex-content-check-input {
    display: flex;
    align-items: center;
  }

  .flex-drop-tax-check {
    display: flex;
    align-items: center;
  }

  .width-check-controller .custom_form_control {
    width: 100%;
  }

  .menu-sub-list-main .menu-sub-item-mui {
    width: 100%;
    text-transform: capitalize;
    justify-content: start;
  }

  .menu-list-msater-list .MuiModal-backdrop+div+.MuiMenu-paper {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    background: #ffffff 0% 0% no-repeat padding-box;
    margin-left: 10px;
  }

  .second-inner-menu-list .MuiModal-backdrop+div+.MuiMenu-paper {
    margin-left: 30px;
  }

  .third-inner-menu-list .MuiModal-backdrop+div+.MuiMenu-paper {
    margin-left: 30px;
  }

  .round-of-drop {
    display: flex;
    align-items: center;
    padding-left: 25px;
  }

  .round-of-drop-label {
    margin-right: 10px;
    min-width: 70px;
    text-align: end;
  }

  .check-detail-wi {
    min-width: 141px;
  }

  .edit_profile_field {
    margin-bottom: 10px;
  }

  .swal2-actions .swal2-styled.swal2-default-outline:focus {
    box-shadow: none !important;
  }

  .set-mrp-box {
    padding-top: 0px !important;
  }

  .sidebar_main a {
    font-size: 14px;
    text-decoration: none;
    color: #000;
    display: block;
    padding: 10px 8px;
  }

  .sidebar_main .cus-accordion {
    box-shadow: none;
    background-color: transparent;
    padding: 6.5px 8px;
    width: 100%;
  }

  .cus-accordion .MuiAccordionSummary-gutters {
    min-height: 22px !important;
    height: 22px;
    padding: 0;
  }

  .cus-accordion .MuiAccordion-region {
    margin-top: 5px;
  }

  .cus-accordion .MuiAccordionDetails-root {
    padding: 0;
  }

  .cus-accordion .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px 0px 0px;
  }

  .cus-accordion .MuiAccordion-root.Mui-expanded {
    margin: 0px 0;
  }

  .cus-accordion .sub-accord {
    box-shadow: none;
    background-color: transparent;
    padding-left: 10px;
    padding-top: 5px;
  }

  .cus-accordion .sub-accord .MuiCollapse-root {
    padding-left: 10px;
  }

  .sub-accord .MuiAccordionSummary-gutters {
    padding: 0;
    margin-bottom: 0px;
  }

  .cus-accordion::before {
    display: none !important;
  }

  .cus-accordion .MuiAccordion-root::before {
    display: none !important;
  }

  .sidebar_btn_list .sidebar-text {
    font-size: 12px;
    line-height: 15px;
    color: #999999;
    font-family: "Poppins-Regular";
  }

  .sidebar_main .ul_sidebar_list .cus-accordion div a {
    color: #999;
    padding: 8px;
  }

  .sidebar_main .ul_sidebar_list .cus-accordion div a:hover {
    color: #000;
  }

  .edit_profile_field .form-label {
    font-size: 14px;
    color: #858585;
    line-height: 25px;
    margin-bottom: 6px;
    margin-top: 0px;
  }

  .custom_form_control fieldset {
    border: 0px;
  }

  .date-of-purchase .MuiStack-root {
    padding-top: 0px;
    overflow: initial;
  }

  .date-of-purchase .MuiInputBase-colorPrimary {
    border-radius: 8px;
    /* height: 31.25px; */
    background: #f2f2f2;
  }

  .account-minusbutton {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  .account-minusbutton .from_login_details {
    margin-bottom: 8px;
  }

  .address-flex {
    display: flex;
  }

  .address-flex .office-address-space {
    width: 100%;
    margin-right: 15px;
  }

  .button-grid {
    display: flex;
    align-items: flex-end;
    margin-bottom: 17px;
  }

  .custom-address .MuiFilledInput-underline {
    padding-top: 12px;
  }

  .custom-address .MuiFilledInput-underline::before,
  .custom-address .MuiFilledInput-underline:hover::before,
  .custom-address .MuiFilledInput-underline.Mui-focused::before {
    border-bottom: 0 !important;
  }

  .address-textarea textarea {
    width: 100%;
    resize: none;
    height: 100px !important;
    border: 0 !important;
    background: #f0f0f0;
    border-radius: 5px;
    padding: 15px;
    outline: none;
  }

  .custom-dropdownbox.dropdown-box .form-control .dropdown-select.with-border {
    border: 1px solid #e3e3e3 !important;
  }

  .document-image {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .document-image-set {
    height: 100px;
    width: 150px;
    object-fit: cover;
    border-radius: 10px;
  }

  .upload-img-flex {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 20px;
  }

  .document-close {
    position: absolute;
    top: -5px;
    right: -5px;
    background: #f2f2f2;
    cursor: pointer;
    border-radius: 50%;
  }

  .upload-btn-main .upload-btn,
  .upload-btn-main .upload-btn:hover {
    background: #f2f2f2;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    font-size: 18px;
    color: #000;
    height: 30px;
    width: 100%;
    line-height: 25px;
    box-shadow: none;
    min-width: 30px;
  }

  .document-close-set .document-close {
    font-size: 15px;
  }

  .set-add-btn {
    display: flex;
    justify-content: flex-start;
  }

  .document-close-set {
    position: relative;
  }

  .balance-top-space {
    margin-top: 30px;
  }

  .op-balance-main {
    padding-left: 20px;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    font-family: "Poppins-SemiBold";
  }

  .binance-btn-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px;
  }

  .set-count-sec .add-set-count-box {
    margin: 0;
  }

  .binance-btn-sec .btn_right_align {
    padding: 0;
  }

  .binance-btn-sec .btn_linear {
    margin-right: 5px;
  }

  .set-count-sec {
    display: flex;
    align-items: center;
  }

  .set-count-sec .add-set-count-box {
    width: max-content !important;
    padding: 10px;
  }

  .set-count-sec .balance-text {
    margin-right: 10px;
  }

  .modal_content:focus {
    outline: none;
  }

  .edit_profile_field .limited-credit-checkbox {
    padding: 0px 0px 0px 5px;
  }

  .from_login_details .MuiInputBase-adornedStart::before {
    border-bottom: 0px;
  }

  .from_login_details .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 0px !important;
  }

  .drop-upper-text {
    text-transform: uppercase;
  }

  .form-group .custom_form_control textarea {
    background: #f2f2f2;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    padding: 12px 20px 13px 20px;
  }

  .form-group textarea {
    background: #f2f2f2;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    width: 100%;
    min-height: 34px;
    padding: 10px;
    max-height: 100px;
    overflow: auto !important;
    outline: none;
  }

  .sales-date-picker .MuiPaper-elevation {
    box-shadow: none;
    padding-top: 0;
  }

  .sales-date-picker .MuiPaper-elevation .MuiTextField-root {
    width: 100%;
    min-width: 100%;
  }

  .summary-table th,
  .summary-table td {
    border: 1px solid #e3e3e3;
  }

  .page-table-main .scrollable-table-container .table-th {
    background-color: #f2f2f2;
    border: 1px solid #e3e3e3;
    color: #000;
    font-size: 12px;
    font-family: "Poppins-Medium";
    line-height: 21px;
    margin: 0;
    overflow: auto;
    padding: 3px;
    text-align: center;
  }

  .page-table-main .scrollable-table-container .table-td {
    border: 1px solid #e3e3e3;
    padding: 3px 5px;
    margin: 0px;
    overflow: auto;
    min-width: 110px;
    text-align: center;
  }

  .genrate-paking-scrollbale-table-main .table {
    min-width: 1800px;
  }

  .table-container.scrollable-table-container {
    padding: 0px;
    border: 1px solid #d3d3d385;
    max-height: 260px;
  }

  ::-webkit-resizer {
    display: none;
  }

  .page-table-main .table .table-th .scrollable-box,
  .page-table-main .table .table-td .scrollable-box {
    resize: horizontal;
    overflow: auto;
    max-width: 300px;
    height: 120px;
    margin: 0px;
    padding: 0px;
    display: block;
    border: 0;
    width: auto;
    height: auto;
    min-height: 20px;
    min-width: 50px !important;
    color: #676767;
  }

  .table-data-text {
    color: #676767;
  }

  .content_right {
    width: calc(100% - 270px) !important;
    flex: calc(100% - 270px) 1 !important;
    min-height: 100vh;
    padding: 0px 0px 20px 260px;
    transition: all 0.5s;
  }

  .card-spacing {
    margin-top: 20px;
  }

  .sales-date-picker .sales-date-picker-grid {
    padding: 0px !important;
  }

  .set-btn-salesorder-main .cancel-btn {
    font-size: 11px;
  }

  .salesorder-img-box {
    display: flex;
    justify-content: space-between;
  }

  .order-img {
    width: 100%;
    height: 125px;
    border-radius: 5px;
  }

  .order-img-box {
    display: flex;
    width: 25%;
    margin-left: 30px;
    align-items: flex-start;
  }

  .set-textarea-box {
    resize: none !important;
  }

  .auto-drop-box .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #1f6feb !important;
  }

  .dropdown-select:active fieldset,
  .dropdown-select.Mui-focused fieldset {
    border: 1px solid #1f6feb !important;
  }

  .dropdown-select-box .MuiSelect-select {
    padding: 2.5px 7px;
    font-size: 11px;
    line-height: 16px;
    color: #8d8989;
    font-family: "Poppins-Regular";
    display: flex;
  }

  .dropdown-select-box fieldset {
    border: 0;
  }

  .set-btn-salesorder-main {
    margin-top: 20px;
  }

  .remark-textarea-error {
    border: 1px solid red !important;
  }

  .size-enabled-input .MuiInputBase-root input {
    background-color: yellow !important;
    text-align: center;
  }

  .MuiTableCell-body .add-more-content-btn {
    display: flex;
    justify-content: center;
  }

  .add-more-content-btn .table-btns {
    min-width: 30px !important;
    min-height: 30px !important;
  }

  .anchor-link {
    cursor: pointer;
  }

  .text-align-center {
    text-align: center !important;
  }

  .text-align-right {
    text-align: right !important;
  }

  .bg_logins-access {
    height: calc(100vh - 118px) !important;
  }

  .login_pd_container-access {
    padding-bottom: 0px !important;
    padding-right: 10px !important;
  }

  .cus-card-main {
    margin: auto;
    border: 1px solid #ececee;
    box-shadow: 0px 10px 15px #0000000d;
    border-radius: 15px;
    width: 350px;
    padding: 20px;
  }

  .cus-card-main-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
  }

  .main-qr-banner-text {
    font-size: 20px;
    line-height: 20px;
    font-family: "Poppins-SemiBold";
    text-align: center;
  }

  .qr-banner-text {
    width: 30%;
  }

  .qr-banner-text p {
    font-size: 14px;
    line-height: 20px;
    font-family: "Poppins-SemiBold";
  }

  .qr-banner-number {
    width: 70%;
  }

  .qr-banner-number p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
  }

  .banner-number-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
  }

  .main-box-banner {
    display: flex;
    align-items: center;
  }

  .banner-img {
    object-fit: contain;
    width: 130px;
    height: 65px;
  }

  .banner-img-box {
    text-align: center;
    margin-top: 8px;
  }

  .banner-border {
    border-style: dotted;
    padding: 5px 0px;
    border-color: #706a6a52;
  }

  .banner-border-set {
    line-height: 10px !important;
  }

  .main-qr-banner-text-new {
    font-size: 14px;
    line-height: 15px;
    font-family: "Poppins-SemiBold";
    text-align: center;
  }

  .main-qr-banner-inner {
    font-size: 14px;
    line-height: 20px;
    font-family: "Poppins-SemiBold";
    text-align: center !important;
  }

  .banner-box-top {
    margin: 2px 0px 10px;
  }

  .customer-set {
    margin-top: 0px !important;
    margin-bottom: 8px !important;
  }

  .product-container {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .packing-sales-table-body tr td {
    padding: 10px 16px !important;
  }

  .disabled-sales-row {
    background-color: #dedede78;
    cursor: not-allowed;
    pointer-events: none;
  }

  .hiddenInput {
    height: 0px;
    width: 0px;
    border: 0px;
  }

  .packing-slip-sales-order-table-container {
    max-height: 300px !important;
    overflow: auto !important;
  }

  .img-icon-box svg {
    display: flex;
    margin: auto;
    width: 23px;
    height: 23px;
  }

  .barcode-icon-box svg {
    display: flex;
    margin: auto;
    width: 20px;
    height: 20px;
  }

  .sales-order-print-container {
    /* padding: 10px; */
    /* border: 1px solid #dbdada; */
    /* margin: 15px; */
    padding-top: 20px;
  }

  .sales-order-print-container .border {
    padding: 10px;
  }

  .sales-voucher-print-container {
    /* padding: 10px; */
    border: 1px solid #dbdada;
    margin: 15px;
  }

  .sales-order-print-preview-container {
    padding: 10px;
    /* border: 1px solid #9f9e9e; */
    margin: 15px;
  }

  .packing-slip-print-container {
    padding: 10px;
    /* border: 1px solid #dbdada; */
    margin: 15px;
  }

  .sales-order-print table {
    width: 100%;
  }

  .sales-order-print table {
    border-collapse: collapse;
  }

  .sales-order-print .table th {
    font-weight: bold;
  }

  .sales-order-print .bold td {
    font-weight: bold;
  }

  .sales-order-print table th,
  .sales-order-print table td {
    font-size: 12px;
    text-align: left;
  }

  .sales-order-print .table th,
  .sales-order-print .table td {
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 12px;
    text-align: center;
  }

  .sales-order-print h3 {
    margin: 0;
    padding: 10px;
  }

  .sales-order-print .border {
    border: 2px solid #000;
    padding: 25px;
  }

  .sales-order-print .right {
    text-align: right;
  }

  .sales-order-print .center {
    text-align: center;
  }

  .sales-order-print .bold td {
    font-weight: bold;
  }

  .print-button {
    text-align: right;
  }

  .red {
    color: red !important;
  }

  .text-center {
    text-align: center !important;
  }

  .padding-10 {
    padding: 10px;
  }

  .export-buttons {
    justify-content: flex-end;
    display: flex;
  }

  .invoice-1-print-container table {
    width: 100%;
  }

  .invoice-1-print-container table {
    border-collapse: collapse;
  }

  .invoice-1-print-container .table th {
    font-weight: bold;
  }

  .invoice-1-print-container .bold td {
    font-weight: bold;
  }

  .invoice-1-print-container table th,
  .invoice-1-print-container table td {
    font-size: 12px;
    text-align: left;
  }

  .invoice-1-print-container .table th,
  .invoice-1-print-container .table td {
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 12px;
    text-align: center;
  }

  .invoice-1-print-container h3 {
    margin: 0;
    padding: 10px;
  }

  .invoice-1-print-container {
    padding-top: 20px;
  }

  .invoice-1-print-container .border {
    border: 1px solid #dbdada;
    padding: 10px 10px 10px 10px;
  }

  .invoice-1-print-container .right {
    text-align: right;
  }

  .invoice-1-print-container .center {
    text-align: center;
  }

  .invoice-1-print-container .bold td {
    font-weight: bold;
  }

  .sales-order-row {
    grid-column-gap: 15px;
    grid-row-gap: 0px;
  }

  .sales-order-scroll-row {
    margin: 20px 0px 40px;
  }

  .date-of-purchase input {
    font-size: 13px;
    color: #000000;
    font-family: "Poppins-Regular";
    line-height: 18px;
  }

  .checkbox-content-flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .checkbox-content-flex .checkbox-lable {
    font-size: 12px;
    line-height: 18px;
    color: #676767;
    font-family: "Poppins-Bold";
    margin-top: 0px;
    text-transform: capitalize;
  }

  .sales-order-submit-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  span.span-data {
    font-size: 12px;
    line-height: 16px;
    font-family: "Poppins-Regular";
    color: #999999;
  }

  .sales-order-b {
    font-size: 13px;
    line-height: 16px;
    font-family: "Poppins-SemiBold";
    color: #000;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid lightgray;
  }

  .modal-header .modal-title {
    font-size: 15px;
    line-height: 17px;
    font-family: "Poppins-SemiBold";
    color: #000;
  }

  .cancel-img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    cursor: pointer;
  }

  .modal-scroll {
    padding: 15px;
    max-height: calc(100vh - 200px);
    overflow: auto;
  }

  .modal-scroll .modal-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
  }

  .modal-scroll .modal-flex .modal-successful-img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

  .modal-body {
    padding: 25px 0px 10px;
  }

  .modal-footer-main {
    padding: 15px;
  }

  .modal-btn-flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
  }

  .form-group .mobile-drop-down {
    background: #f2f2f2;
    border-radius: 8px;
    padding-left: 10px;
    width: 100%;
  }

  .form-group .MuiInput-root:after {
    border-bottom: 0px !important;
  }

  .form-group .MuiInput-root::before {
    border-bottom: 0px !important;
  }

  .form-group .mobile-drop-down input {
    border: 0px;
    padding-left: 0px;
  }

  .mobile-drop-down .MuiPhoneNumber-positionStart::after {
    content: "";
    background-image: url("../images/png/downward-arrow.png");
    height: 10px;
    width: 10px;
    position: absolute;
    right: 11px;
    background-size: 12px 12px;
  }

  .mobile-drop-down .MuiPhoneNumber-flagButton svg {
    height: 20px;
    width: 20px;
  }

  .mobile-drop-down .MuiPhoneNumber-flagButton {
    min-width: 50px;
    justify-content: start;
  }

  .mobile-drop-down button:hover {
    background-color: transparent;
  }

  .common-box {
    margin-bottom: 50px;
  }

  .btn-flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  .form-group .checkbox {
    padding: 0px 0px 0px 10px;
  }

  .product-show-img {
    height: 150px;
    width: 100%;
    max-width: 300px;
    padding: 10px 21px 5px 21px;
  }

  .dropdown-box .form-control {
    width: 100%;
  }

  .modal-qunty-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .add-set-table-main {
    margin-top: 30px;
  }

  .date-of-purchase .custom_form_control input {
    border: 0px;
  }

  .pass_group_main .MuiInputBase-root {
    padding-right: 0;
  }

  .order-img-main-box {
    display: flex;
    max-width: 25%;
    margin-left: auto;
  }

  .MuiAutocomplete-listbox .MuiAutocomplete-option {
    font-size: 12px;
    line-height: 15px;
    color: #999999;
    font-family: "Poppins-Regular";
    text-transform: capitalize;
    padding: 5px 10px !important;
  }

  .date-of-purchase .custom_form_control {
    width: 100%;
  }

  .tabs-outer-main .tab {
    color: #c85803;
    font-size: 12px;
    font-family: "Poppins-Medium";
    line-height: 19px;
    padding: 5px 15px;
    text-transform: capitalize;
    min-height: 20px;
  }

  .tabs-outer-main .tabs {
    min-height: 20px;
  }

  .tabs-outer-main .tab.Mui-selected {
    color: #c85803;
  }

  .tabs-outer-main .MuiTabs-indicator {
    background-color: #c85803;
    height: 2px;
  }

  .tabpanel>.MuiBox-root {
    padding: 15px 0 0px;
  }

  .modal-header .modal-subtitle {
    font-size: 13px;
    line-height: 17px;
    color: #a5a5a5;
    margin-top: 11px;
  }

  .radio-main .radio-group {
    gap: 20px;
  }

  .radio-main .radio-group .radio-control {
    margin: 0px;
  }

  .radio-main .MuiRadio-root {
    padding: 0px 10px 0px 0px;
    color: #fd6602;
  }

  .radio-main .radio-group svg {
    color: #fd6602;
  }

  .radio svg {
    color: #fd6602;
  }

  .radio-main .radio {
    padding: 0px 10px 0px 0px;
  }

  .generate-btn-end {
    display: flex;
    justify-content: flex-end;
  }

  .checkbox-main .checkbox {
    color: #fd6602;
    padding: 0px;
  }

  .checkbox-main .checkbox.Mui-checked {
    color: #fd6602;
  }

  .checkbox-main svg {
    font-size: 15px;
  }

  .final-amount-row {
    margin-top: 20px;
  }

  .input-box .MuiChip-root {
    margin: 0px;
  }

  .input-box .MuiChip-root svg {
    color: #00000096;
  }

  .profile-change-pass-card {
    max-width: 500px;
  }

  .tabs-card {
    background: #ffffff;
    box-shadow: 0px 10px 15px #0000000d;
    border: 1px solid #ececee;
    border-radius: 15px;
    padding: 15px;
  }

  .add-product-image-main {
    height: 30px;
    width: 100%;
    border-radius: 8px;
  }

  .add-product-image-main .file-upload-btn,
  .add-product-image-main .file-upload-btn:hover {
    height: 100%;
    padding: 0;
    border-radius: 8px;
    width: 100%;
    font-size: 12px;
    line-height: 27px;
    color: #999999;
    font-family: "Poppins-Regular";
    outline: none;
    border: 0px;
    text-align: left;
    justify-content: flex-start;
  }

  .footr-btn-flex {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .table-container.inner-modal-table-container {
    padding: 0px;
  }

  .uncleard-cheque-card {
    margin-bottom: 30px;
  }

  .size-down-card-main {
    margin-top: 30px;
  }

  .inner-modal-card {
    background: #ffffff;
    box-shadow: 0px 10px 15px #0000000d;
    border: 1px solid #ececee;
    width: 100%;
    padding: 15px;
    border-radius: 15px;
  }

  .price-list-up-card {
    margin-top: 30px;
  }

  .gross-row .gross-lable {
    font-size: 12px;
    line-height: 18px;
    color: #676767;
    min-height: 18px;
    margin: 0;
    font-family: "Poppins-SemiBold";
  }

  .gross-row {
    margin: 20px 0px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
  }

  .input-box .sales-type-text {
    text-align: right;
  }

  .m-30 {
    margin: 20px 0px;
  }

  .modal-search-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
  }

  .page-table-main .table td {
    font-size: 10px;
    line-height: 14px;
    color: #717171;
    font-family: "Poppins-Regular";
    padding: 3px 15px 3px 15px;
    white-space: nowrap;
  }

  .page-table-main .table-cell-th {
    font-size: 11px;
    line-height: 16px;
    color: #35383d;
    font-family: "Poppins-Medium";
    padding: 3px 15px 3px 15px;
    border: 1px solid #e3e3e3;
  }

  .table-container.summary-table-container thead tr:first-child th {
    background: #f2f2f2;
  }

  .table-summary-bg .view-titles {
    font-size: 15px;
    line-height: 20px;
    color: #000;
    margin-bottom: 5px;
    text-align: center;
  }

  .table-summary-bg .view-subtitle {
    font-size: 13px;
    line-height: 18px;
    color: #999999;
    text-align: center;
  }

  .table-container.summary-table-container {
    padding: 0px;
  }

  .sales-entry-table-report table {
    min-width: 1500px;
  }

  .btn-flex-space {
    margin-right: 15px;
  }

  .barcode-box-main {
    background-color: #c85803;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 35px;
    margin: auto;
  }

  .barcode-box-main svg {
    color: #fff;
    height: 20px;
    width: 20px;
  }

  .scrollable-img {
    display: flex;
    margin: auto;
    width: 23px;
    height: 23px;
  }

  .form-group .custom_form_control_textarea.scrollable-textarea {
    padding: 2.5px 5px;
  }

  .scrollable-textarea-group {
    display: flex;
  }

  .pakingslip-list-table-main .scrollable-table-container {
    height: auto;
    border: 0px;
  }

  .bold-text {
    color: #000c;
  }

  .summary-details-report-table-main .table {
    min-width: 2100px;
  }

  .summary-itemvise-report-table-main .table {
    min-width: 1500px;
  }

  .summary-partvise-report-table-main .table {
    min-width: 1500px;
  }

  .summary-salesdetails-report-table-main .table {
    min-width: 2200px;
  }

  .summary-salesitemvise-report-table-main .table {
    min-width: 1500px;
  }

  .summary-salespartvise-report-table-main .table {
    min-width: 1500px;
  }

  .salesorder-img-flex {
    display: flex;
    gap: 10px;
  }

  .gerate-paking-order-img-box {
    max-width: 100%;
    width: 100%;
  }

  .custom-dropdownbox .MuiStack-root {
    padding: 0px;
  }

  .summary-purchasregister-report-table-main .table {
    min-width: 1500px;
  }

  .summary-purchasorder-report-table-main .table {
    min-width: 1500px;
  }

  .summary-purchasordersumary-report-table-main .table {
    min-width: 1500px;
  }

  .summary-seles-report-details-table-main .table {
    min-width: 1200px;
  }

  .summary-seles-party-wise-table-main .table {
    min-width: 1200px;
  }

  .summary-seles-party-sumary-table-main .table {
    min-width: 1200px;
  }

  .summary-sales-itemvise-details-table-main .table {
    min-width: 1200px;
  }

  .summary-itemvise-summary-table-main .table {
    min-width: 1200px;
  }

  .summary-sales-order-value-table-main .table {
    min-width: 1200px;
  }

  .summary-principlal-vise-table-main .table {
    min-width: 1200px;
  }

  .modal-search-box.search_list_main .search_input {
    margin-right: 0px;
  }

  .modal-header .modal-close-btn {
    padding: 0px;
    min-width: 30px;
  }

  .password-hint {
    color: grey;
  }

  .table-th .role-checkbox {
    padding: 0px;
  }

  .table-th .role-checkbox svg {
    height: 15px;
    width: 15px;
    color: #fd6602;
  }

  .table-td .role-checkbox svg {
    height: 15px;
    width: 15px;
  }

  .head-check-main .role-checkbox {
    margin-left: 5px;
  }

  .add-role-table-main .table-td {
    padding: 2px;
  }

  .add-role-table-main .table-td .role-checkbox {
    padding: 0px;
  }

  .setting-icon-bg .company-setup-icon {
    color: #fff;
    height: 14px;
    width: 14px;
    display: flex;
  }

  .compnay-setup-content-main {
    margin-bottom: 10px;
    border: 1px solid #cfcfcf59;
    border-radius: 5px;
    padding: 15px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .compnay-setup-content-main:last-child {
    margin-bottom: 0;
  }

  .compnay-setup-content-main .company-setup-titles {
    color: #c85803;
    font-size: 14px;
    line-height: 23px;
    font-family: "Poppins-Medium";
    margin-bottom: 10px;
    position: relative;
  }

  .company-setup-switch-main .MuiSwitch-root {
    margin: 5px 0px;
  }

  .company-setup-flex .company-switch-lable {
    font-size: 13px;
    line-height: 17px;
    font-family: "Poppins-Medium";
    color: #717171;
  }

  .compnay-setup-content-main .MuiFormControlLabel-root {
    margin-right: 0;
  }

  .company-setup-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-right-flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .admin-dropdown-box .admin-form-control {
    width: 100%;
    position: relative;
  }

  .admin-dropdown-select .MuiSelect-select {
    color: #999999;
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins-Regular";
    display: flex;
    padding: 5.6px 25px;
    border-radius: 4px;
    border: 1px solid #ededed;
  }

  .admin-grey-down {
    position: absolute;
    top: 17.92px;
    right: 21.92px;
    height: 10px;
    width: 10px;
    object-fit: contain;
  }

  .MuiMenu-list .admin-menuitem {
    color: #999999;
    font-size: 12px;
    line-height: 20px;
    padding: 5px 10px;
    font-family: "Poppins-Regular";
  }

  .MuiMenu-list .MuiMenuItem-root.Mui-selected.Mui-focusVisible {
    background-color: #ededed;
  }

  .admin-form-group .admin-form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    border-width: 0px;
  }

  .checkbox-content-flex .checkbox-lable.user-check-lable {
    margin-bottom: 0px;
  }

  .user-start-date-input svg {
    height: 15px;
    width: 15px;
  }

  .email-icon-bg .email-icon {
    height: 13px;
    width: 13px;
    object-fit: contain;
    color: #fff;
    display: flex;
    justify-content: center;
  }

  .email-icon-bg {
    background-color: #fd6602;
  }

  .setting-icon-bg {
    background-color: gray;
  }

  .edit-company-row {
    margin-top: 20px;
  }

  .edit-company-row:first-child {
    margin-top: 0;
  }

  .calendar-icon {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 10px;
    position: absolute;
    top: 30%;
    left: 4%;
  }

  .admin-header-dropdown-main {
    position: relative;
  }

  .header-drop-icons {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 10px;
  }

  .account-minusbutton .btn_linear .linear-btn-main {
    min-width: 30px;
    padding: 2px;
    height: 31px;
    font-size: 18px;
  }

  .auto-drop-box .MuiOutlinedInput-root {
    padding: 0 !important;
  }

  .auto-drop-box .inner_custom_form_control input {
    padding: 6.89px 15px !important;
    font-size: 12px;
    line-height: 16px;
  }

  .checkbox-content-flex .checkbox-main {
    display: flex;
  }

  .ul_sidebar_list .li_sidebar_list .sidebar_btn_list {
    gap: 10px;
  }

  .sidebar-icons {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }

  /* .ul_sidebar_list .li_sidebar_list.active {
      background-color: #000000;
  }

  .ul_sidebar_list .li_sidebar_list.active .sidebar_title {
      color: #fff;
  } */
  .active {
    background-color: #000000;
    border-radius: 5px;
    padding: 7px 8px;
  }

  .active .sidebar_title {
    color: #fff;
  }

  .ul_sidebar_list .li_sidebar_list .sidebar_title {
    font-size: 12px;
    line-height: 15px;
    color: #999999;
    font-family: "Poppins-Regular";
  }

  .menu-sub-list-main .menu-sub-item-mui {
    font-size: 12px;
    line-height: 17px;
    color: #999999;
    font-family: "Poppins-Regular";
  }

  .login_main {
    background-color: #ffffff;
  }

  .MuiAutocomplete-listbox::-webkit-scrollbar {
    width: 4px;
  }

  .MuiAutocomplete-listbox::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
    background: #888;
  }

  .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .check-icon-main {
    display: flex;
  }

  .check-icon-main svg {
    height: 20px;
    width: 20px;
  }

  .company-name-box .company-name-text {
    font-size: 9px;
    line-height: 14px;
    color: #c85803;
    font-family: "Poppins-SemiBold";
    letter-spacing: 1px;
    border-bottom: 1px solid #c85803;
  }

  .company-name-box .company-name-title {
    font-size: 8px;
    line-height: 12px;
    color: #999999;
    font-family: "Poppins-Regular";
    letter-spacing: 1px;
  }

  .company-name-box {
    padding: 3px 8px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .mobile-berger-main {
    display: none;
  }

  .mobile-menu-icon {
    height: 25px;
    width: 25px;
    object-fit: contain;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 3px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #88888866;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #88888866;
  }

  .form-group .MuiOutlinedInput-root {
    border-radius: 8px;
  }

  .common-modal .modal-content-inner {
    border: 1px solid #d1d5db85;
    border-radius: 10px;
    max-height: calc(100vh - 40px);
    width: calc(100% - 30px);
    background-color: #fff;
    box-shadow: 0px 10px 15px #0000000d;
  }

  .common-modal .modal-content-inner {
    max-width: 600px;
  }

  .common-modal .modal-content-inner.modal-content-large-inner {
    max-width: 900px;
  }

  .date-of-purchase .MuiTextField-root {
    min-width: 100% !important;
  }

  .scrollable-flex {
    display: flex;
    gap: 5px;
  }

  .radio-mian .MuiButtonBase-root {
    padding: 0px;
  }

  .invoice-logo {
    min-width: 100px;
    max-height: 100px;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .common-modal .modal-content-inner.bill-content-modal-inner {
    max-width: 900px;
  }

  .common-modal .modal-content-inner.bill-nested-content-modal-inner {
    max-width: 500px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .page-table-main .table td.table-not-found-td {
    padding: 0px;
    border: 0px;
  }

  .data-not-found-main {
    box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
    background-color: #fff;
    border: 1px solid #ededed;
    border-radius: 5px;
    padding: 9px;
    width: 100%;
    margin: 10px 0px 0px;
  }

  .data-not-found-main .data-not-found-text {
    font-size: 12px;
    line-height: 18px;
    color: #717171;
    font-family: "Poppins-Medium";
    text-align: center;
  }

  .gross-row {
    background: #ffffff;
    box-shadow: 0px 10px 15px #0000000d;
    border: 1px solid #ececee;
    border-radius: 15px;
    padding: 15px;
  }

  .jv-bill-total {
    font-size: 12px;
    line-height: 18px;
    color: red;
    min-height: 18px;
    margin: 0px 0px 2px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .stock-report-input-box {
    margin-bottom: 10px;
  }

  .stock-report-main {
    margin: 15px;
  }

  .webview-spinner-main {
    display: flex;
  }

  .webview-spinner-main .MuiCircularProgress-root {
    color: #fff;
  }

  .webview-spinner-main .webview-spinner {
    height: 17px !important;
    width: 17px !important;
  }

  .btn_linear .webview-submit-btn,
  .btn_linear .webview-submit-btn:hover {
    width: 100%;
  }

  .webview-button-container {
    width: 100%;
  }

  .debit-print table {
    width: 100%;
  }

  .webview-main-container {
    background-color: #ffffff;
  }

  .purchase-order-print-container {
    margin-top: 15px;
  }

  .webview-input-box .form-group .custom_form_control input {
    padding: 10px 20px 10px 15px;
  }

  .webview-input-box .auto-drop-box .inner_custom_form_control input {
    padding: 10px 15px !important;
  }

  .stock-transfer-tabs {
    margin-top: 50px;
  }

  .stock-transfer-flex {
    display: flex;
    gap: 10px;
  }

  .advance-modal-btn {
    margin-top: 22px;
  }

  .btn-img {
    height: 17px;
    width: 17px;
    object-fit: contain;
  }

  .btn-box .linear-btn-main,
  .btn-box .linear-btn-main:hover,
  .btn-box .linear-btn-main:focus {
    min-width: 30px;
    padding: 5px 10px;
    outline: none;
  }

  .btn-box {
    margin-top: 20px;
  }

  .input-box-flex {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
  }

  .common-modal .success-modal {
    width: 500px;
  }

  .table-data-text {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
  }

  .black-tr td {
    color: #000000;
  }

  .genrate-paking-scrollbale-table-main.page-table-main .scrollable-table-container .table-td:nth-child(1) {
    min-width: 30px;
  }

  .genrate-paking-scrollbale-table-main.page-table-main .scrollable-table-container .table-td:nth-child(2) {
    min-width: 40px;
  }

  .genrate-paking-scrollbale-table-main.page-table-main .scrollable-table-container .table-td:nth-child(3) {
    min-width: 50px;
  }

  .genrate-paking-scrollbale-table-main.page-table-main .scrollable-table-container .table-td:nth-child(4) {
    min-width: 300px;
  }

  .genrate-salesentry-scrollbale-table-main.page-table-main .scrollable-table-container .table-td:nth-child(1) {
    min-width: 30px;
  }

  .genrate-salesentry-scrollbale-table-main.page-table-main .scrollable-table-container .table-td:nth-child(2) {
    min-width: 40px;
  }

  .genrate-salesentry-scrollbale-table-main.page-table-main .scrollable-table-container .table-td:nth-child(3) {
    min-width: 60px;
  }

  .genrate-salesentry-scrollbale-table-main.page-table-main .scrollable-table-container .table-td:nth-child(4) {
    min-width: 300px;
  }

  .table-highligt-row {
    background-color: #c78b3569;
  }

  .table-row-hilight {
    background-color: rgb(215 235 78 / 34%);
  }

  .table-highligt-row-red {
    background-color: rgba(224, 98, 44, 0.34);
  }

  .custom-flex-btn {
    display: flex;
    justify-content: space-between;

  }

  .flex-btn-custom {
    display: flex;
    gap: 20px;
  }

  .custom-select-dropdown .MuiSelect-select {
    padding: 5.5px 14px;
    font-size: 12px;
    color: #676767;
    font-size: 10px;
    line-height: 11px;
  }

  .custom-select-dropdown fieldset {
    display: none;
  }

  .MuiList-root .custom-menuItem-table {
    font-size: 10px;
  }

  /* .select-custom-form-control fieldset {
    display: none;
  } */

  .select-custom-form-control .MuiSelect-select {
    padding: 6.5px 14px;
    font-size: 12px;
    color: #999;
    line-height: 18px;
    font-family: Poppins-Regular;
  }

  .MuiList-root .custom-menuItem-dropdrown {
    font-size: 12px;
    color: #999999;
    font-family: "Poppins-Regular";
  }

  .flex-chequePrint-item {
    display: flex;
    padding-bottom: 15px;
  }

  .custom-flex-chequePrint-item {
    gap: 20px;
    justify-content: center;
    padding-bottom: 27px;
  }

  .custom-flex-chequePrint-item p {
    min-width: 120px;
  }

  .print-cheque-main-box {
    border: 1px solid #6666;
    margin-top: 25px;
    padding: 10px;
    border-radius: 10px;
  }

  .chequePrint-item-text {
    min-width: 160px;
    text-align: start;
  }

  .chequePrint-item-text .custom-check-box {
    width: 20px;
    height: 20px;
    fill: #000;
    color: #000;
  }

  .chequePrint-item-text .custom-check-box svg {
    fill: #000;
  }

  .chequePrint-item-text p {
    color: rgb(53, 56, 61);
    font-family: Poppins-SemiBold;
    font-size: 12px;
  }

  .chequePrint-table-header {
    padding: 15px 15px;
  }

  .chequePrint-table-header p {

    color: rgb(53, 56, 61);
    font-family: Poppins-SemiBold;
    font-size: 15px;
    line-height: 28px;

    padding: 6px 15px;
  }

  .custom-btn-flex-center {
    justify-content: center;
  }

  .chequePrint-table-header .sub-text {
    display: block;
    line-height: 2px;
  }

  .date-of-purchase .custom-date-form-control.input-error {
    border: 1px solid red;
    border-radius: 8px;
  }

  .cheque-main-box {
    border: 1px solid #000;
    width: 100%;
  }

  .cheque-main-box .MuiTableContainer-root .MuiTable-root tbody tr td {
    border-bottom: none;
    padding: 7px;

  }

  .table-footer-flex {
    display: flex;
    justify-content: space-between;
  }