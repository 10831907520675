@media screen and (min-width: 992px) and (max-width:1199px) {
    .header_listing {
        overflow-x: scroll;
        max-width: 500px;
    }
}

@media screen and (max-width:991px) {
    .mobile-berger-main {
        display: block;
    }

    .sidebar_left {
        max-width: 0;
        flex: 1 1;
        top: 0;
        background-color: #000000;
        padding-top: 10px;
        padding-bottom: 30px;
        z-index: 9999;
    }

    .sidebar_deactive {
        max-width: 0;
        min-width: 0;
        left: -290px;
    }

    .content_right {
        padding: 0px 0px 0px 0px;
    }

    .title_top_fix {
        left: 0;
    }
    .cus-accordion .MuiAccordionSummary-expandIconWrapper svg{
        color: #fff;
    }
    .ul_sidebar_list .li_sidebar_list{
        background-color: transparent;
        margin-bottom: 0;
    }
    .black_dash_logo {
        justify-content: flex-start;
    }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .page-table-main table {
        min-width: 1150px;
    }
    .edit_row {
        margin-top: 20px;
    }
    .final-amount-row {
        padding: 0px 15px;
    }
    .gross-row {
        padding: 0px 15px;
    }
    .min-width-drop-tax{
        min-width: initial !important;
        max-width: initial;
    }
    .list_card_details .card_list_ul {
        grid-template-columns: repeat(2, 1fr);
    }

    .header_logo_col1 {
        flex: 0 0 10%;
        max-width: 10%;
    }
    .header_listing {
        overflow-x: scroll;
        max-width: 260px;
    }
}

@media screen and (max-width:767px) {
    .login_pd_container {
        padding: 30px 15px;
    }

    .bg_logins {
        height: 50vh;
    }

    .logo_login {
        height: 60.13px;
    }

    .login_title_content {
        margin-top: 25px;
    }

    .login_title_content .main_title_login {
        font-size: 25px;
        line-height: 33px;
    }

    .login_title_content .main_content_login {
        font-size: 14px;
        line-height: 23px;
    }

    .right_content_login {
        max-width: 100%;
        padding-top: 30px;
        flex: 0 0 100%;
    }
    .header_logo_col1 {
        flex: 0 0 15%;
        max-width: 15%;
    }
    .header_listing {
        overflow-x: scroll;
        max-width: 200px;
        margin-left: 10px;
    }
    .company-name-box, .admin-header-dropdown-main{
        display: none;
    }
    .list_card_details .card_list_ul {
        grid-template-columns: repeat(1, 1fr);
    }
    .pd_content_main {
        padding: 15px;
    }
    .over_text_scroll {
        height: initial;
    }
    .flex_justify {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
    }
    .web_main_content{
        padding-top: 0;
    }
    .title_top_fix{
        position: initial;
        padding: 0;
    }
    .flex-content-check-input {
        align-items: flex-start;
        flex-direction: column;
    }
    .round-of-drop {
        padding-left: 0;
        flex-direction: column;
        align-items: self-start;
    }
    .flex-drop-tax-check {
        align-items: flex-start;
        flex-direction: column;
    }
    .min-width-drop-tax {
        min-width: 100% !important;
        margin-right: 0px;
        max-width: 100%;
    }
    .check-detail-wi {
        min-width: 100%;
    }
    .round-of-drop-label {
        margin-right: 0;
        min-width: 100%;
        text-align: start;
    }
    .width-check-controller {
        width: 100%;
    }
}

@media screen and (min-width: 320px) and (max-width:374px) {}